

































import Vue from "vue";

export default Vue.extend({
  name: "PageHeader",
  methods: {
    scrollTo(element: string) {
      this.$scrollTo(element, 500, { easing: 'ease-in' })
    }
  }
});
